import * as React from 'react'
import { cva, VariantProps } from 'class-variance-authority'

import { cn } from '@/lib/utils'

const standardCardVariants = cva('rounded-lg py-7 shadow-lg lg:py-8', {
  variants: {
    variant: {
      'light-olive': ['bg-card-secondary', 'border-t-4 border-orange-600'],
      white: ['bg-card-primary'],
    },
    defaultVariants: {
      variant: 'white',
    },
  },
})
export interface StandardCardProps
  extends React.HTMLAttributes<HTMLDivElement>,
    VariantProps<typeof standardCardVariants> {
  asChild?: boolean
}

const StandardCard = React.forwardRef<HTMLDivElement, StandardCardProps>(
  ({ className, variant, asChild = false, ...props }, ref) => (
    <div
      ref={ref}
      className={cn(standardCardVariants({ variant }), className)}
      {...props}
    />
  )
)
StandardCard.displayName = 'StandardCard'

const StandardCardTitle = React.forwardRef<
  HTMLHeadingElement,
  React.HTMLAttributes<HTMLHeadingElement>
>(({ className, children, ...props }, ref) => (
  <h2
    ref={ref}
    className={cn('heading-xs-bold text-card-foreground', className)}
    {...props}
  >
    {children}
  </h2>
))
StandardCardTitle.displayName = 'StandardCardTitle'

const StandardCardContent = React.forwardRef<
  HTMLDivElement,
  React.HTMLAttributes<HTMLDivElement>
>(({ className, children, ...props }, ref) => (
  <>
    <div
      ref={ref}
      className={cn(
        'prose flex flex-col px-4 prose-ul:pl-[6px] prose-li:!text-[22px]',
        className
      )}
      {...props}
    >
      {children}
    </div>
  </>
))
StandardCardContent.displayName = 'StandardCardContent'

const StandardCardDescription = React.forwardRef<
  HTMLDivElement,
  React.HTMLAttributes<HTMLDivElement>
>(({ className, children, ...props }, ref) => (
  <div
    ref={ref}
    className={cn(
      'body-lg-regular text-card-foreground prose-a:text-primary-button',
      className
    )}
    {...props}
  >
    {children}
  </div>
))
StandardCardDescription.displayName = 'StandardCardDescription'

const StandardCardAction = React.forwardRef<
  HTMLDivElement,
  React.HTMLAttributes<HTMLDivElement>
>(({ className, children, ...props }, ref) => (
  <div ref={ref} className={cn('px-0 pt-7 lg:pt-8', className)} {...props}>
    {children}
  </div>
))
StandardCardAction.displayName = 'StandardCardAction'

const StandardCardEyebrow = React.forwardRef<
  HTMLDivElement,
  React.HTMLAttributes<HTMLDivElement>
>(({ className, ...props }, ref) => (
  <div
    ref={ref}
    className={cn('body-md-regular text-card-foreground', className)}
    {...props}
  />
))
StandardCardEyebrow.displayName = 'StandardCardEyebrow'

export {
  StandardCard,
  StandardCardDescription,
  StandardCardContent,
  StandardCardTitle,
  StandardCardAction,
  StandardCardEyebrow,
}
