'use client'

import dynamic from 'next/dynamic'
import Link from 'next/link'
import { TypeFeatureBlock } from '@/contentful/types'
import { documentToReactComponents } from '@contentful/rich-text-react-renderer'
import { X } from 'lucide-react'
import { Config } from 'react-player'
import { WistiaConfig } from 'react-player/wistia'

import { Button } from '@/components/ui/button'
import {
  Dialog,
  DialogClose,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from '@/components/ui/dialog'
import { ContentfulImage } from '@/components/contentful-image'

const ReactPlayer = dynamic(() => import('react-player'), { ssr: false })

export function FeaturedBlock({
  fields: { title, content, variant, videoId, videoTranscript, links, asset },
}: TypeFeatureBlock<'WITHOUT_UNRESOLVABLE_LINKS'>) {
  const config: WistiaConfig = {}

  switch (variant) {
    case 'Image': {
      return (
        <div className="flex flex-col items-center gap-y-6 bg-hudson-navy-900 sm:mx-8 sm:my-7 sm:gap-y-7 sm:rounded-xl lg:mx-11 lg:flex-row lg:pr-8 lg:pt-8">
          <div className="flex flex-1 flex-col justify-center gap-y-7 self-stretch p-5 sm:px-8 sm:py-7 lg:gap-y-8 lg:py-11">
            <div className="prose text-white marker:!text-white prose-headings:text-white prose-a:text-white">
              {documentToReactComponents(content!)}
            </div>
            {links && (
              <Link href={links.fields.href}>
                <Button
                  className="w-fit"
                  variant="secondary"
                  data-testid={`${links.fields.name}-button`}
                >
                  {links.fields.name}
                </Button>
              </Link>
            )}
          </div>
          <div className="relative aspect-square w-[335px] md:w-[576px] lg:self-end">
            {asset && (
              <ContentfulImage
                alt={asset.fields.description || ''}
                className="object-cover"
                src={asset.fields.file?.url || ''}
                fill
              />
            )}
          </div>
        </div>
      )
    }
    case 'Video': {
      return (
        <div className="flex flex-col items-center bg-hudson-navy-900 px-5 py-8 text-white sm:mx-8 sm:my-7 sm:rounded-xl sm:px-8 sm:py-7 lg:mx-11 lg:my-8 lg:px-8 lg:py-10">
          <p className="heading-lg-regular mb-5 text-center sm:mb-7">{title}</p>
          <div className="mb-2 flex h-[188px] w-[335px] flex-col items-start sm:h-[350px] sm:w-[600px] lg:h-[450px] lg:w-[800px]">
            <ReactPlayer
              width="100%"
              height="100%"
              url={`https://support.wistia.com/medias/${videoId}`}
              controls={true}
              config={config as Config}
            />
          </div>
          {videoTranscript && (
            <div className="self-start lg:w-[800px] lg:self-auto">
              <Dialog>
                <DialogTrigger asChild>
                  <Button className="whitespace-normal !p-1 text-left text-xl underline hover:bg-transparent">{`View transcript of ${title}`}</Button>
                </DialogTrigger>
                <DialogContent className="flex max-h-[80vh] max-w-[350px] flex-col border-olive-400 shadow-none sm:max-w-[600px]">
                  <DialogHeader>
                    <DialogTitle className="!heading-lg-regular text-left">
                      {title}
                      <hr className="border-olive-400" />
                    </DialogTitle>
                  </DialogHeader>
                  <div className="prose grow overflow-y-auto py-4 pr-4 prose-hr:border-olive-400">
                    {documentToReactComponents(videoTranscript)}
                  </div>
                  <DialogClose asChild>
                    <Button
                      className="absolute right-5 top-4 sm:right-5"
                      variant={'primary-accordion-link'}
                    >
                      <X />
                      <span className="sr-only">Close</span>
                    </Button>
                  </DialogClose>
                </DialogContent>
              </Dialog>
            </div>
          )}
        </div>
      )
    }
    default: {
      return null
    }
  }
}
