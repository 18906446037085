'use client'

import { useState } from 'react'
import { documentToReactComponents } from '@contentful/rich-text-react-renderer'
import { BLOCKS } from '@contentful/rich-text-types'

import { cn } from '@/lib/utils'
import { Button } from '@/components/ui/button'
import { ContentfulImage } from '@/components/contentful-image'
import {
  VideoCard,
  VideoCardContent,
  VideoCardDescription,
  VideoCardImage,
  VideoCardLink,
  VideoCardTitle,
} from '@/components/video-card'

import { gridVariants } from './grid'

interface VideoGridWrapperProps {
  blocks: any[]
  className?: string
}

const renderOptions = {
  renderNode: {
    [BLOCKS.EMBEDDED_ASSET]: (node: Node) => {
      const asset = node.data.target.fields
      return (
        <div className="not-prose max-w-100 relative -mb-4 flex h-32 md:h-52 lg:mb-2 ">
          <ContentfulImage src={asset.file.url} alt={asset.description} fill />
        </div>
      )
    },
  },
}

export function VideoCardPaginatedGrid({
  blocks,
  className,
}: VideoGridWrapperProps) {
  const [visibleCount, setVisibleCount] = useState(6)
  const hasMoreItems = blocks.length > visibleCount

  return (
    <div className="space-y-7 lg:space-y-9">
      <div className={cn(gridVariants({ layout: 'benefit' }), className)}>
        {blocks.slice(0, visibleCount).map(({ fields }) => (
          <VideoCard key={fields.headline}>
            <VideoCardImage
              src={`https://${fields.image?.fields?.file?.url}`}
              alt={fields.image?.fields?.description || ''}
            />
            <VideoCardContent>
              <VideoCardTitle>{fields.headline}</VideoCardTitle>
              <VideoCardDescription>
                {documentToReactComponents(fields.content, renderOptions)}
              </VideoCardDescription>
              {fields.cta && (
                <VideoCardLink href={fields.cta.fields.href}>
                  {fields.cta.fields.name}
                </VideoCardLink>
              )}
            </VideoCardContent>
          </VideoCard>
        ))}
      </div>
      {hasMoreItems && (
        <div className="flex justify-center">
          <Button
            variant="primary-outline"
            className="w-full"
            onClick={() => setVisibleCount((prev) => prev + 6)}
            data-testid="load-more-button"
          >
            Load more
          </Button>
        </div>
      )}
    </div>
  )
}
