import(/* webpackMode: "eager", webpackExports: ["FeaturedBlock"] */ "/app/client/app/(marketing)/[[...slug]]/_components/featured-block.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["VideoCardPaginatedGrid"] */ "/app/client/app/(marketing)/[[...slug]]/_components/video-card-paginated-grid.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["ArticleCarousel"] */ "/app/client/app/(marketing)/articles/[...slug]/_components/article-carousel.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["AllFeaturedTags"] */ "/app/client/app/(marketing)/categories/[categoryId]/_components/all-featured-tags.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["ArticleGrid","ArticleGridHeading","ArticleGridSearch","ArticleGridList"] */ "/app/client/components/article-grid.tsx");
;
import(/* webpackMode: "eager" */ "/app/client/components/contentful-image.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/client/components/datalayer.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/client/components/scroll-handler.tsx");
;
import(/* webpackMode: "eager" */ "/app/client/components/ui/accordion.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["Carousel","CarouselContent","CarouselItem","CarouselPagination"] */ "/app/client/components/ui/carousel.tsx");
;
import(/* webpackMode: "eager" */ "/app/client/node_modules/next/dist/client/image-component.js");
;
import(/* webpackMode: "eager" */ "/app/client/node_modules/next/dist/client/link.js");
